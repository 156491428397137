
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ColumnSelect",
  props: {
    column_id: {
      type: String,
      default: undefined,
    },
    table_id: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:column_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const columnData = computed(() => store.getters.Columns);
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isColumnRequired = (value) => {
      if (props.required && !value) {
        return t("rcolumn");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "column",
      isColumnRequired
    );

    const selectColumn = (query) => {
      if (query !== "") {
        inputElements.value.loading = true;
        setTimeout(() => {
          inputElements.value.loading = false;
          inputElements.value.options = inputElements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputElements.value.options = inputElements.value.list;
      }
    };

    watch(
      () => props.column_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => props.table_id,
      (first) => {
        const values = columnData.value.filter((x) => x.table === first);
        inputElements.value.list = values;
        inputElements.value.options = values;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.column_id;
      }, 500);
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      selectColumn,
    };
  },
};
