import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "d-sm-flex flex-row justify-content-between mb-6" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "text-darker mb-0" }
const _hoisted_5 = { class: "text-gray-500 fs-5 fw-bold" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "me-auto p-1" }
const _hoisted_8 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_basic_select = _resolveComponent("table-basic-select")!
  const _component_VarsForm = _resolveComponent("VarsForm")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("ivars")), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString($setup.dataObject.length) + " " + _toDisplayString(_ctx.$t("ielements")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
            type: "text",
            class: "form-control form-control-search w-sm-300px",
            placeholder: `${_ctx.$t('isearch')}`,
            onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onSearch && $setup.onSearch(...args)))
          }, null, 40, _hoisted_8), [
            [_vModelText, $setup.search]
          ])
        ]),
        ($setup.canCreate)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-custom btn-app-custom",
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_modal_1",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.createElement && $setup.createElement(...args)))
            }, _toDisplayString(_ctx.$t("btnNew")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_table_basic_select, {
      class: "mt-5",
      headers: $setup.header,
      data: $setup.dataObject,
      buttons: $setup.tableButtons,
      total: $setup.dataObject.length,
      "can-update": $setup.canUpdate,
      "can-delete": $setup.canDelete,
      "can-show": $setup.canShow,
      onEventEmitButton: $setup.actionsButton
    }, null, 8, ["headers", "data", "buttons", "total", "can-update", "can-delete", "can-show", "onEventEmitButton"]),
    ($setup.dialogVisible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: $setup.dialogVisible,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.dialogVisible) = $event)),
          width: "30%",
          "custom-class": "dialog-container",
          title: $setup.modalMode === 'add' ? _ctx.$t('mt_var_add') : _ctx.$t('mt_var_edit')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VarsForm, {
              "var-id": $setup.itemID,
              onSuccess: $setup.resetModal
            }, null, 8, ["var-id", "onSuccess"])
          ]),
          _: 1
        }, 8, ["modelValue", "title"]))
      : _createCommentVNode("", true)
  ]))
}