
import { onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import ColumnSelect from "@/components/catalogs-select/ColumnSelect.vue";
import TableSelect from "@/components/catalogs-select/TableSelect.vue";
import { useStore } from "vuex";

interface FormCatalog {
  id?: number;
  name: string;
  table?: number;
  column?: number;
  code: string;
}

export default {
  name: "VarsForm",
  components: {
    ColumnSelect,
    TableSelect,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    varId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const addElement = ref(false);
    const isLoading = ref(false);
    const tableData = ref<[] | any>([]);
    const form = ref<FormCatalog>({
      id: undefined,
      name: "",
      table: undefined,
      column: undefined,
      code: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const isCodeRequired = (value) => {
      if (!value) {
        return t("rcode");
      }
      return true;
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.varId) {
        ApiService.get("/api/vars/view/" + props.varId).then(({ data }) => {
          form.value = { ...data };
        });
      }
    });

    const createElement = async (payload, resetForm) => {
      try {
        const resp = (await ApiService.post("/api/vars", payload)).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (payload, resetForm) => {
      ApiService.put("/api/vars/" + props.varId, payload)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const payload = {
          id: form.value.id,
          name: form.value.name,
          table: form.value.table,
          column: form.value.column,
          code: form.value.code,
        };
        if (form.value.id) {
          updateElement(payload, resetForm);
        } else {
          createElement(payload, resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      addElement,
      isLoading,
      tableData,
      onSumbit,
      isNameRequired,
      isCodeRequired,
      updateElement,
      createElement,
    };
  },
};
