
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "TableSelect",
  props: {
    table_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:table_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const tableData = computed(() => store.getters.Tables);
    const inputElements = ref({
      loading: false,
      options: tableData.value,
      list: tableData.value,
    });

    const isTableRequired = (value) => {
      if (props.required && !value) {
        return t("rtable");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "table",
      isTableRequired
    );

    const selectTable = (query) => {
      if (query !== "") {
        inputElements.value.loading = true;
        setTimeout(() => {
          inputElements.value.loading = false;
          inputElements.value.options = inputElements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputElements.value.options = inputElements.value.list;
      }
    };

    watch(
      () => props.table_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.table_id;
      }, 500);
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      selectTable,
    };
  },
};
